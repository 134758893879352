import React from "react";
import { Helmet } from "react-helmet";

import "./FourOhFour.scss";

const FourOhFour = ({ staticContext = {} }) => {
    staticContext.status = 404;
    return (
        <div className="FourOhFour">
            <Helmet>
                <title>Page Not Found</title>
                {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-85974704-1"></script>
                <script>
                    {
                        `window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'UA-85974704-1');`
                    }
                </script>
            </Helmet>
            {/* //@TODO find svg or something */}
            <section className="error">
                <h2>Woops! We can't find this page... </h2>
                <h1>404</h1>
            </section>
        </div>
    );
};

export default FourOhFour;
