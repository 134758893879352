import React from "react";
import Header from "./../../components/Header/Header";
import Footer from "./../../components/Footer/Footer";
import { Helmet } from "react-helmet";
import ScheduleScreenshot from "./../../assets/images/drive/drive-schedule-ride.png";
import SettingScreenshot from "./../../assets/images/drive/drive-boss-settings-1.png";
import GetStartedHero from "./../../assets/images/drive/get-started-hero.jpeg";
import BusinessCard1 from "./../../assets/images/drive/Business-Card-1.png";
import BusinessCard2 from "./../../assets/images/drive/Business-Card-2.png";

import "./GetStarted.scss";

const GetStarted = () => {
    return (
        <div className="GetStarted">
            <Helmet>
                <title>
                    Learn more about starting your own driving business -
                    dumpling
                </title>
                {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-85974704-1"></script>
                <script>
                    {
                        `window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'UA-85974704-1');`
                    }
                </script>
            </Helmet>
            <Header />
            <section className="get-started-hero">
                <div className="get-started-hero-container">
                    <h1>
                        Get started becoming your own{" "}
                        <span className="highlight">boss.</span>
                    </h1>
                    <p className="subheader">
                        Run your own personal driving business from your phone.
                    </p>
                    <a href="/pricing" className="button-button pill">
                        Get Started
                    </a>
                </div>
                <img src={GetStartedHero} alt="hero one" />
            </section>

            <section className="drive">
                <h2>Introducing Dumpling Drive</h2>
            </section>

            <section className="app-details">
                <div className="details-container">
                    <div className="details">
                        <div className="details-info info-bg-1">
                            <h3>
                                Manage your business with the{" "}
                                <span className="highlight">Boss app</span>{" "}
                            </h3>
                            <p>
                                The Boss app allows you to manage every aspect
                                of your business, from setting your own pricing,
                                to keeping track of your clients. This is where
                                you’ll review any incoming ride requests and
                                accept or deny them.{" "}
                            </p>
                        </div>
                        <img
                            className="screenshot"
                            src={SettingScreenshot}
                            alt="Dumpling drive allows business owners to set own pricing and control when and where they get clients"
                        />
                    </div>
                    <div className="details">
                        <div className="details-info info-bg-2">
                            <h3>
                                Book scheduled rides with the{" "}
                                <span className="highlight">Client app</span>{" "}
                            </h3>
                            <p>
                                The Drive client app allows clients to connect
                                directly with you with a unique 5 digit code or
                                your QR code. Clients are able to keep a card on
                                file, pay, and tip securely, so you don’t have
                                to worry about a third party credit card
                                processor.
                            </p>
                        </div>
                        <img
                            className="screenshot"
                            src={ScheduleScreenshot}
                            alt="dumpling drive business owners control their clients and money"
                        />
                    </div>
                    <div className="details">
                        <div className="details-info info-bg-1">
                            <h3>
                                Grow your clientele with{" "}
                                <span className="highlight">
                                    marketing materials
                                </span>
                            </h3>
                            <p>
                                Networking and building a clientele can be a
                                challenge, so we provide you with templated
                                resources so you can spread the word about your
                                business easily. You can personalize and print
                                business cards and seatback marketing materials
                                directly from our online marketing portal.
                            </p>
                        </div>
                        <div className="screenshots">
                            <img
                                className=" card-one"
                                src={BusinessCard1}
                                alt="dumpling drive free marketing materials for your local business"
                            />
                            <img
                                className=" card-two"
                                src={BusinessCard2}
                                alt="dumpling drive free marketing materials for your local business"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="how-to">
                <h2>How to get your driving business started</h2>
                <div className="steps-container">
                    <div className="step-card">
                        <h3 className="highlight">Documents</h3>
                        <ul>
                            <li>Valid Driver’s License</li>
                            <li>Local Transport License</li>
                            <li>Commercial auto insurance</li>
                        </ul>
                    </div>

                    <div className="step-card">
                        <h3 className="highlight">Sign up</h3>
                        <p>
                            We know any good business takes time to build. So
                            we’re giving you 60 days free to start building your
                            clientele!{" "}
                        </p>
                    </div>

                    <div className="step-card">
                        <h3 className="highlight">Hustle</h3>
                        {/* <p>
                            Connect with your best customers, favorite rides,
                            and community to help spread the word that you’re
                            offering scheduled rides. Knowing who is going to
                            pick you up is more important now than ever before!{" "}
                        </p> */}
                        <p>
                            Connect with your best customers, favorite rides,
                            and community to spread the word that you’re
                            offering scheduled rides.{" "}
                        </p>
                    </div>
                </div>
            </section>

            <section className="faq">
                <h2>Frequently Asked Questions</h2>
                <div className="tabs">
                    <div className="tab">
                        <input type="checkbox" id="check1" name="rd" />
                        <label className="tab-label" htmlFor="check1">
                            How is Dumpling different from other rideshare
                            platforms?
                        </label>
                        <div className="tab-content">
                            With dumpling Drive, you own your own private
                            driving business. This means you are in control of
                            your own licensing, insurance, clientele, schedule,
                            and pricing.
                        </div>
                    </div>
                    <div className="tab">
                        <input type="checkbox" id="check2" name="rd" />
                        <label className="tab-label" htmlFor="check2">
                            How do I get clients?
                        </label>
                        <div className="tab-content">
                            As the business owner you are responsible for
                            finding your own clients through your personal or
                            professional networks, other gigs, or other avenues.
                        </div>
                    </div>
                    <div className="tab">
                        <input type="checkbox" id="check3" name="rd" />
                        <label className="tab-label" htmlFor="check3">
                            How do I make money?
                        </label>
                        <div className="tab-content">
                            Drivers on the dumpling Drive platform are in
                            complete control of their earnings, via setting
                            their own pricing in the Boss app. All earnings go
                            directly to the business owner. However, as the
                            business owner the Driver is responsible for the 3%
                            credit card processing fee and $0.30 transaction
                            charge associated with each payment.
                        </div>
                    </div>

                    <div className="tab">
                        <input type="checkbox" id="check4" name="rd" />
                        <label className="tab-label" htmlFor="check4">
                            Do I need my own insurance?
                        </label>
                        <div className="tab-content">
                            You are responsible for having all appropriate
                            licenses, insurance and local approvals to provide
                            transportation to your clients.
                        </div>
                    </div>
                    <div className="tab">
                        <input type="checkbox" id="check5" name="rd" />
                        <label className="tab-label" htmlFor="check5">
                            How much does Dumpling Drive cost?
                        </label>
                        <div className="tab-content">
                            The first 60 days are completely free! Once you’ve
                            determined if Dumpling Drive is the right fit for
                            you and your business, you may continue service for
                            $20 per month.
                        </div>
                    </div>
                    <div className="tab">
                        <input type="checkbox" id="check6" name="rd" />
                        <label className="tab-label" htmlFor="check6">
                            Where is dumpling available?
                        </label>
                        <div className="tab-content">
                            Dumpling Drive is available nationwide in the United
                            States.
                        </div>
                    </div>
                    <div className="tab">
                        <input type="checkbox" id="check7" name="rd" />
                        <label className="tab-label" htmlFor="check7">
                            What licenses and permits do I need?
                        </label>
                        <div className="tab-content">
                            You need a valid driver’s license, local transport
                            license, and commercial auto insurance. If you have
                            any questions about these items, please research
                            your local laws and regulations to ensure you’re
                            meeting local requirements.
                        </div>
                    </div>
                    <div className="tab">
                        <input type="checkbox" id="check8" name="rd" />
                        <label className="tab-label" htmlFor="check8">
                            Can I cancel my subscription at any time?
                        </label>
                        <div className="tab-content">
                            Yes. To cancel your subscription, please contact{" "}
                            <a
                                href="https://intercom.help/dumpling-drive---business-owners/en/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                customer support
                            </a>
                            .
                        </div>
                    </div>
                    <div className="tab">
                        <input type="checkbox" id="check9" name="rd" />
                        <label className="tab-label" htmlFor="check9">
                            Does this work for multiple cars/drivers?
                        </label>
                        <div className="tab-content">
                            Currently Dumpling Drive is built and intended for a
                            single car and driver.
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-started-cta">
                <h2>Start your free trial today</h2>
                <a href="/pricing" className="button-button pill">
                    Get Started
                </a>
            </section>
            <Footer />
        </div>
    );
};

export default GetStarted;
