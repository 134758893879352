import React from "react";
import DumplingLogo from "./../../assets/images/long/green.png";
import Email from "./../../assets/icons/email.svg";
import Facebook from "./../../assets/icons/facebook.svg";
import Twitter from "./../../assets/icons/twitter.svg";
import Instagram from "./../../assets/icons/instagram.svg";
import "./Footer.scss";

const Footer = () => {
    return (
        <section className="Footer">
            <footer className="footer-container">
                <div className="nav">
                    <div className="links">
                        <p className="link-header body-strong">Products</p>
                        <ul>
                            <li>
                                <a
                                    href="https://drive.dumpling.us"
                                    className="link body-light"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Drive
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://dumpling.us"
                                    className="link body-light"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Grocery
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="links">
                        <a
                            href="http://help.dumpling.us/en/"
                            className="link-header body-strong link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Support
                        </a>
                    </div>

                    <div className="links">
                        <p className="link-header body-strong">Dumpling</p>
                        <ul>
                            <li>
                                <a
                                    href="https://dumpling.us/about"
                                    className="link body-light"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    About
                                </a>
                            </li>
                            {/* <li>
                                <a
                                    href="https://medium.com/dumpling-blog"
                                    className="link body-light"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Small Business Guidebook
                                </a>
                            </li> */}

                            <li>
                                <a
                                    href="mailto:press@dumpling.us"
                                    className="link body-light"
                                    //@TODO: Check emails open
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Media Inquires
                                </a>
                            </li>

                            <li>
                                <a
                                    href="https://dumplingmarketing.s3.us-east-2.amazonaws.com/dumplingTerms.pdf"
                                    className="link body-light"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Terms of Service
                                </a>
                            </li>

                            <li>
                                <a
                                    href="https://dumplingmarketing.s3.us-east-2.amazonaws.com/PrivacyPolicy.pdf"
                                    className="link body-light"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Privacy Policy
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="dumpling">
                        <img
                            src={DumplingLogo}
                            alt="Dumpling local service based business logo"
                        />
                        <p className="subtitle">
                            We build the tools that empower anyone to start, run
                            and grow their own local, service-based business.
                        </p>
                    </div>
                    <div className="contact">
                        <p className="subtitle">© 2021 Dumpling Inc.</p>
                        <div>
                            <a
                                href="mailto:press@dumpling"
                                // target="_blank"
                                // rel="noopener noreferrer"
                            >
                                <img src={Email} alt="Email" className="icon" />
                            </a>
                            <a
                                href="https://www.facebook.com/dumpling.us/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={Facebook}
                                    alt="Facebook"
                                    className="icon"
                                />
                            </a>
                            <a
                                href="https://twitter.com/dumplingus"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={Twitter}
                                    alt="Twitter"
                                    className="icon"
                                />
                            </a>
                            <a
                                href="https://www.instagram.com/shopdumpling"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={Instagram}
                                    alt="Instagram"
                                    className="icon"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </section>
    );
};

export default Footer;
