import "normalize.css";
import React from "react";
import ReactDom from "react-dom";
import App from "./App";
// import BrowserRouter
import { BrowserRouter } from "react-router-dom";
import "./base.scss";

ReactDom.render(
    <BrowserRouter>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </BrowserRouter>,
    document.getElementById("root")
);
