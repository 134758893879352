import React from "react";
import Header from "./../../components/Header/Header";
import Footer from "./../../components/Footer/Footer";
import { Helmet } from "react-helmet";
import "./DrivePricing.scss";

const DrivePricing = () => {
    return (
        <div className="DrivePricing">
            <Helmet>
                <title>Start your driving business today! - dumpling</title>
                {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-85974704-1"></script>
                <script>
                    {
                        `window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'UA-85974704-1');`
                    }
                </script>
            </Helmet>
            <Header />
            <section className="pricing">
                <h1>
                    Start your driving{" "}
                    <span className="highlight">business today!</span>
                </h1>
                <h3 className="subheader-light info">
                    {/* Now more than ever, Riders want to know who is picking them
                    up. Now’s the time to start building trustworthy
                    relationships with your best clients and start growing your
                    private driving business.{" "} */}
                    The Dumpling Drive Beta program is now closed. If you'd like
                    to be notified when the program is re-opened, please click
                    the sign-up link to leave your information.
                </h3>
                <div className="pricing-container">
                    <div className="instructions">
                        <h2>Get started instantly.</h2>
                        {/* <p className="subheader">
                        Requirements are simple {" & "} easy
                    </p> */}
                        <p>
                            Have your credit card info ready and be ready to
                            share a picture of yourself and driver’s license.
                            <br /> <br /> Download the Boss app from the app
                            store and you’re ready to start!
                        </p>
                    </div>

                    <div className="plan">
                        <div className="plan-header">
                            <h3>Plan: </h3>
                            <p className="subheader-light">$20/month</p>
                        </div>
                        <ul>
                            <li>
                                The Drive Boss app to set up your business
                                {" & "}
                                manage your clients
                            </li>
                            <li>
                                A personalized app for your clients, customized
                                to represent your business{" "}
                            </li>
                            <li>Secure payment processing </li>
                            <li>Daily direct deposit </li>
                            <li>
                                Access to marketing templates to help grow your
                                business{" "}
                            </li>
                            <li>
                                Business owner support, to answer any questions
                                you may have
                            </li>
                        </ul>
                        <a
                            href="https://dumpling.typeform.com/to/CQWOBXYZ"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="button-button pill"
                        >
                            Sign up for Beta Program
                        </a>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default DrivePricing;
