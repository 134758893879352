import React from "react";
import { Route, Switch } from "react-router-dom";
import FourOhFour from "./FourOhFour/FourOhFour";
import Drive from "./Drive/Landing/Drive";
// import ThankfulPage from "./Thankful/Thankful";
import GetStarted from "./Drive/GetStarted/GetStarted";
import DrivePricing from "./Drive/Pricing/DrivePricing";
import "./App.scss";

const App = () => {
  return (
    <div className="App">
      <Switch>
        <Route
          exact
          path="/"
          component={Drive}  
        />
        <Route
          exact
          path="/getstarted"
          component={GetStarted}  
        />
        <Route
          exact
          path="/pricing"
          component={DrivePricing}  
        />
        {/* <Route
          exact
          path="/thankful"
          component={ThankfulPage}
        /> */}
        <Route component={FourOhFour}/>

      </Switch>
    </div>
  );
};

export default App;
