import React, {useState} from "react";
import DriveLogo from "./../../assets/images/stacked/drive-green.svg";
import "./Header.scss";

//@TODO - header should take in object w/ urls
const Header = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const handleMenuChange = () => {
        setMenuVisible(prevMenuVisible => !prevMenuVisible);
    }

    return (
        <section className="Header">
            <header className="header-container">
                <a className="logo-link" href="/">
                    <img
                        className="logo"
                        src={DriveLogo}
                        alt="Dumpling We build the tools that empower anyone to start, run and grow their own local, service-based business."
                    />
                </a>
                <nav className={`nav ${(menuVisible ? "open" : "")}`} onClick={handleMenuChange}>
                    <ul className="nav-list">
                        <li className="item">
                            <a href="/">Home</a>
                        </li>
                        <li className="item">
                            <a href="/getstarted">Why Dumpling Drive</a>
                        </li>
                        <li className="item">
                            <a href="/pricing">Get Started</a>
                        </li>
                        <li className="item">
                            <a href="https://dumpling.typeform.com/to/CQWOBXYZ" target="_blank" rel="noopener noreferrer" className="button-button">Sign up for Beta Program</a>
                        </li>
                    </ul>
                </nav>
            </header>
        </section>
    );
};

export default Header;
