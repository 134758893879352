import React from "react";
import Header from "./../../components/Header/Header";
import Footer from "./../../components/Footer/Footer";
import { Helmet } from "react-helmet";
import LandingHeroOne from "./../../assets/images/drive/landing-hero-1.jpeg";
import LandingHeroTwo from "./../../assets/images/drive/landing-hero-2.jpeg";
import LandingHeroThree from "./../../assets/images/drive/landing-cta.jpeg";
import ClientScreenshot from "./../../assets/images/drive/drive-schedule-ride.png";
import Person from "./../../assets/icons/person.svg";
import Fredrick from "./../../assets/images/drive/fredrick.jpg";
import Robert from "./../../assets/images/drive/robert.jpg";
import Star from "./../../assets/icons/star.svg";
import "./Drive.scss";

const DriveLanding = () => {
    return (
        <div className="DriveLanding">
            <Helmet>
                <title>Power your private driving business - dumpling</title>
                {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-85974704-1"></script>
                <script>
                    {
                        `window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'UA-85974704-1');`
                    }
                </script>
            </Helmet>
            <Header />
            <section className="drive-hero">
                <h1>
                    It's more than a gig. It's your{" "}
                    <span className="highlight">business.</span>
                </h1>
                <p className="subheader">
                    Run your own personal driving business from your phone.
                </p>
                <a href="/getstarted" className="button-button pill">
                    Learn More
                </a>
                <div className="images">
                    <img
                        src={LandingHeroOne}
                        alt="hero image"
                        className="hero-one"
                    />
                    <img
                        src={LandingHeroTwo}
                        alt="hero image"
                        className="hero-two"
                    />
                    <img
                        src={LandingHeroThree}
                        alt="hero image"
                        className="hero-three"
                    />
                </div>
            </section>

            <section className="body">
                <h2>Introducing Dumpling Drive</h2>
                <p className="subheader-light">
                    Dumpling Drive helps you grow your private driving business
                    as an alternative to on demand apps, or pair it with driving
                    for traditional rideshare platforms. The Dumpling Drive Beta
                    program is now closed. If you'd like to be notified when the
                    program is re-opened, please click the sign-up link to leave
                    your information.
                </p>
                <div className="details-container">
                    <img
                        className="screenshot"
                        src={ClientScreenshot}
                        alt="some text"
                    />
                    <div className="details">
                        <div className="details-content">
                            <h3 className="">
                                <span className="highlight">Schedule</span>{" "}
                                rides with your best{" "}
                                <span className="highlight">clients</span>{" "}
                            </h3>
                            <p>
                                Growing your business is all about building
                                relationships with clients you know and trust.
                                Clients are only able to request rides with you,
                                once they’ve connected with you directly via the
                                Dumpling Drive app.
                            </p>
                        </div>
                        <div className="details-content">
                            <h3 className="">
                                Set your
                                <span className="highlight">
                                    {" "}
                                    own prices
                                </span>{" "}
                            </h3>
                            <p>
                                You get to customize every aspect of your
                                pricing by setting a base rate, as well as a per
                                minute and per mile rate. Setting your own
                                prices ensures that your earnings not only cover
                                your time, but any other work related expenses
                                you have.
                            </p>
                        </div>
                        <div className="details-content">
                            <h3 className="">
                                You're in control of{" "}
                                <span className="highlight">
                                    your earnings!
                                </span>
                            </h3>
                            <p>
                                You set your own prices and suggested tip
                                percentages and get earnings deposited after
                                every order
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="steps">
                <h2>Get Started</h2>
                <div className="steps-container">
                    <div className="step-card">
                        {/* <div className="step-icon">
                            <img src={Growth} alt="grow your business" />
                        </div> */}
                        <h3 className="highlight">Step One</h3>
                        <p>Review requirements to get started</p>
                        <ul>
                            <li>Valid Driver’s License</li>
                            <li>Local Transport License</li>
                            <li>Commercial auto insurance</li>
                        </ul>
                    </div>

                    <div className="step-card">
                        {/* <div className="step-icon">
                            <img src={Growth} alt="grow your business" />
                        </div> */}
                        <h3 className="highlight">Step Two</h3>
                        <p>Sign up for FREE 60 day trial </p>
                        <ul>
                            <li>Setup your business </li>
                            <li>Start networking with clients </li>
                            <li>Get to know the app!</li>
                        </ul>
                    </div>

                    <div className="step-card">
                        {/* <div className="step-icon">
                            <img src={Growth} alt="grow your business" />
                        </div> */}
                        <h3 className="highlight">Step Three</h3>
                        <p>Grow your business!</p>
                        <ul>
                            <li>Schedule regular rides </li>
                            <li>Build your network through referrals</li>
                            <li>
                                Ask friends{" & "}family to help spread the word
                            </li>
                        </ul>
                    </div>
                </div>

                <a href="/getstarted" className="button-button pill">
                    Learn More
                </a>
            </section>

            <section className="testimonials">
                <h2>What people are saying about dumpling</h2>
                <div className="testimonials-container">
                    <div className="banner">
                        <div className="testimonials-card">
                            <div className="header">
                                <img
                                    className="light-border profile-img"
                                    src={Person}
                                    alt="Ted a dumpling business owner"
                                />
                                <div>
                                    <p className="body-light">Ted S.</p>
                                    <p className="body-light">Phoenix, AZ</p>
                                    {/* <p className="body-light">Business Owner</p> */}
                                    <div className="star-ctn">
                                        <img src={Star} alt="star" />
                                        <img src={Star} alt="star" />
                                        <img src={Star} alt="star" />
                                        <img src={Star} alt="star" />
                                        <img src={Star} alt="star" />
                                    </div>
                                </div>
                            </div>
                            <p className="body-light">
                                Dumpling has provided better fares per ride due
                                to low fees taken. I can then pass those savings
                                onto my passengers.
                            </p>
                        </div>

                        <div className="testimonials-card">
                            <div className="header">
                                <img
                                    className="light-border profile-img"
                                    src={Fredrick}
                                    alt="Fredrick a dumpling business owner"
                                />
                                <div>
                                    <p className="body-light">Fredrick E.</p>
                                    <p className="body-light">
                                        Baton Rouge, LA
                                    </p>
                                    {/* <p className="body-light">Business Owner</p> */}
                                    <div className="star-ctn">
                                        <img src={Star} alt="star" />
                                        <img src={Star} alt="star" />
                                        <img src={Star} alt="star" />
                                        <img src={Star} alt="star" />
                                        <img src={Star} alt="star" />
                                    </div>
                                </div>
                            </div>
                            <p className="body-light">
                                Control is what this is really all about. I can
                                change customer’s pricing or give them
                                discounts. I can do whatever i want to do
                                because Dumpling gives me that ability.
                            </p>
                        </div>

                        <div className="testimonials-card">
                            <div className="header">
                                <img
                                    className="light-border profile-img"
                                    src={Robert}
                                    alt="Ted a dumpling business owner"
                                />
                                <div>
                                    <p className="body-light">Robert P.</p>
                                    <p className="body-light">Denver, CO</p>
                                    {/* <p className="body-light">Business Owner</p> */}
                                    <div className="star-ctn">
                                        <img src={Star} alt="star" />
                                        <img src={Star} alt="star" />
                                        <img src={Star} alt="star" />
                                        <img src={Star} alt="star" />
                                        <img src={Star} alt="star" />
                                    </div>
                                </div>
                            </div>
                            <p className="body-light">
                                Now I can actually communicate with my clients;
                                customize my prices and services to their needs;
                                and get paid almost the entire amount of my
                                fare, not giving away 20% to 30% in fees to the
                                big biz driver apps.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="landing-cta">
                <h2>Start your free trial today</h2>
                <a href="/getstarted" className="button-button pill">
                    Learn More
                </a>
            </section>
            <Footer />
        </div>
    );
};

export default DriveLanding;
